import "./Administrator.css";
import Questions from "../questions/Questions";
import Administrator from "./Administrator";
import icoAziende from '../images/aziende_convezionate.png'
import test from '../images/1.jpg';
import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { isMobile } from 'react-device-detect';
import AdministratorService from '../../../../services/AdministratorService';

const welcome = 'Ti aiuteremo a trovare quello che cerchi';
const search = 'Per semplicità puoi inserire il comune o cap?';

export default function Administrators() {
    const [isMobileView, setIsMobileView] = useState(isMobile);
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [results, setResults] = useState([]);
    const [totalResults, setTotalResults] = useState(0);
    // Ottengo il valore del campo di ricerca
    const searchValue = watch('searchTerm', '');
    // Funzione per gestire il submit del form
    const onSubmit = (data) => {
        // Chiama il servizio con la query
        AdministratorService.getFilter(data.searchTerm)
            .then((res) => {
               // Estrarre i dati specifici dalla struttura complessa di response.data
               const resultsData = res.data || [];
               const total = res.data.length || 0;

               setResults(resultsData); // Salva i risultati
               setTotalResults(total); // Salva il totale dei risultati
            }).catch((error) => {
                console.log(error);
        });
    };
    return (
        <div id="wrapper">
            <div className ="content">
                <section className ="parallax-section">
                    <div className="bg"></div>
                    <div className ="overlay"></div>
                    <div className ="hero-section-wrap fl-wrap">
                        <div className ="container">
                            <div className ="intro-item fl-wrap">
                                <h2>{welcome}</h2>
                            </div>
                            <div className="main-search-input-wrap">
                                <div className="main-search-input fl-wrap">
                                     <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="main-search-input-item">
                                            <input className={`${errors.searchTerm ? "error" : ""}`}
                                                type="text"
                                                placeholder={errors.searchTerm ? "Contenuto richiesto!" : `${search}`}
                                                {...register("searchTerm", {
                                                    required: "Contenuto richiesto!"
                                                })}
                                             />
                                        </div>
                                        <button type="submit" className="main-search-button">Cerca</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className ="no-padding">
                    <div className="col-list-wrap fh-col-list-wrap left-list">
                        <div className ="container">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="listsearch-header fl-wrap">
                                        <h3>Risultati ottenuti : <span>{totalResults}</span></h3>
                                        <div className="listing-view-layout">
                                            <ul>
                                                <li><a className={`grid ${isMobileView ? "" : "active"}`} onClick={() => {setIsMobileView((prevView) => !prevView);}}><i className="fa fa-th-large"></i></a></li>
                                                <li><a className={`list ${isMobileView ? "active" : ""}`} onClick={() => {setIsMobileView((prevView) => !prevView);}}><i className="fa fa-list-ul"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="list-main-wrap fl-wrap card-listing">
                                        {results.map((result,index) => (
                                            <Administrator key={index} listCardAdmin={isMobileView} name={result.name} description={result.description}
                                                address={result.address} cap={result.cap} comune={result.comune} provincia={result.provincia} />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Questions />
            </div>
        </div>
  );
}